import { createTheme, Theme } from '@mui/material';
import { buttonwoodDarkThemeOptions, buttonwoodLightThemeOptions } from 'common-client';

const lightThemeOptions = {
  ...buttonwoodLightThemeOptions,
  // Add overwrites here
};

export const lightTheme: Theme = createTheme(lightThemeOptions);

const darkThemeOptions = {
  ...buttonwoodDarkThemeOptions,
  // Add overwrites here
};

export const darkTheme: Theme = createTheme(darkThemeOptions);
